<template>
  <OnboardingLayout :on-action="handleSave" :disabled="disabled" :on-copy-onboarding="onCopyAction"
                    :onboardingData="onboardingData">
    <div class="onboarding-card">
      <div class="warning-wrapper" v-if="disabled">
        <div class="left">
          <img src="@/assets/images/warningWhite.svg"/>
        </div>

        <div class="right">
          <h2>{{ $t('view_only_mode') }}</h2>
          <span>
          {{ $t('view_only_mode_description') }}
        </span>
        </div>
      </div>

      <div class="card-wrapper">
        <div class="title-card-border"></div>
        <input class="title-input w-100" :placeholder="this.$t('add_your_onboarding')" v-model="onboardingData.name"/>
      </div>


      <OnboardQuestion :onUpdate="handleQuestionUpdate" v-for="(question,index) in onboardingData.questions"
                       :question="question" :index="index" :disabled="disabled"/>

      <div class="bottom-actions">
        <button class="add-question-button no-text-select" @click="addQuestion">
          <img src="@/assets/images/plusBlue.svg"/>
          <span>{{ $t('add_a_question') }}</span>
        </button>
      </div>
    </div>

  </OnboardingLayout>
</template>

<script>
import OnboardingLayout from "@/components/layouts/OnboardingLayout.vue";
import OnboardQuestion from "@/components/OnboardQuestion.vue";
import OnboardingService from "@/service/onboardingService";
import Switch from "@/components/Switch.vue";
import testService from "@/service/testService";
import {handleErrorResponse} from "@/utils/utils";
import onboardingService from "@/service/onboardingService";

export default {
  name: 'OnboardingDetails',
  components: {Switch, OnboardQuestion, OnboardingLayout},
  data() {
    return {
      onboardingData: {
        name: '',
        status: 1,
        questions: [{
          name: '',
          description: '',
          shortDescription: '',
          content: {
            type: 'single',
            options: [
              {
                id: 0,
                name: '',
              },
            ],
          },
        }]
      },
      disabled: false,
    }
  },

  methods: {
    addQuestion() {

      this.onboardingData.questions.push({
        name: '',
        description: '',
        shortDescription: '',
        content: {
          type: 'single',
          options: [
            {
              id: 0,
              name: '',
            },
          ],
        },
      });
    },
    handleSave() {
      const routeParams = this.$route.params;
      const self = this;
      if (routeParams.id === 'new') {

        OnboardingService.addOnboarding(this.onboardingData).then(function (response) {
          if (response.data.responseType === 'success') {
            //başarılı
            self.$router.push('/onboarding/' + response.data.data.onboardingId);
          } else {
            //başarısız //toast mesaj gösterilecek
          }
        }).catch((error) => {
          // Hata mesajını network'ten al ve kullanıcıya göster
          handleErrorResponse(error, this.$snackbar);
        });

      } else {
        OnboardingService.updateOnboarding(this.onboardingData).then(function (response) {
          if (response.data.responseType === 'success') {
            //başarılı
            self.$router.push('/onboarding');
          } else {
            //başarısız //toast mesaj gösterilecek
          }
        }).catch((error) => {
          // Hata mesajını network'ten al ve kullanıcıya göster
          handleErrorResponse(error, this.$snackbar);
        });
      }
    },
    initPage() {
      const routeParams = this.$route.params;

      if (routeParams.id !== 'new') {
        let self = this;
        OnboardingService.getOnboardingDetail(routeParams.id).then(function (response) {
          self.onboardingData = response.data.data;
        });
      }

      if (!this.onboardingData.status) {
        this.disabled = true;
      }
    },

    onCopyAction() {
      onboardingService.getCopy(this.$route.params.id)
          .then((response) => {
            // Eğer işlem başarılı olursa
            this.$snackbar.add({
              text: "Onboarding copied successfully",
              type: "success",
            });
            // Yeni onboarding sayfasına yönlendirme yap
            this.$router.push("/onboarding/" + response.data.data.onboardingId);
          })
          .catch((error) => {
            // Hata mesajını network'ten al ve kullanıcıya göster
            handleErrorResponse(error, this.$snackbar);
          });
    }


  },
  watch: {
    $route() {
      this.initPage();
    }
  },
  mounted() {
    this.initPage();
  }
}
</script>

<style scoped>

.onboarding-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.warning-wrapper {
  background-color: #F09460;
  border: 1px solid #df8958;
  padding: 15px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.warning-wrapper .left {
  margin-right: 15px;
}

.warning-wrapper .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.warning-wrapper .left img {
  width: 72px;
  height: 72px;
}

.warning-wrapper h2 {
  font-size: 16px;
  margin: 0;
  font-family: euclid_medium, sans-serif;
  color: #fff;
}

.warning-wrapper span {
  font-size: 12px;
  color: #EAECF0;
}

.add-question-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
}

.add-question-button:hover {
  background-color: #f3f4ff;
}
</style>