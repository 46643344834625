import axios from '../axios';

const onboardingService = {
    getOnboarding(page, filters = []) {
        return axios.get(`/v1/admin/onboarding/store?page=${page}`).then((response) => {
            console.log(response); // Gelen yanıtı kontrol edin
            return response;
        });
    },

    getAllOnboarding() {
        return axios.get('/v1/admin/onboarding/store?list=true');
    },

    addOnboarding(data) {
        data = this.convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/onboarding/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    getCopy(onboardingId) {
        return axios.post('/v1/admin/onboarding/copy', {
            onboardingId: onboardingId
        });
    },

    updateOnboarding(data) {
        data = this.convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/onboarding/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getOnboardingDetail(id) {
        return axios.get(`/v1/admin/onboarding/getFromId/${id}`);
    },

    convertBooleanValuesToNumbers(data) {
        const convertValue = (value) => {
            if (typeof value === 'boolean') {
                return value ? 1 : 0;
            }
            if (typeof value === 'object') {
                for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                        value[key] = convertValue(value[key]);
                    }
                }
            }
            return value;
        };

        return convertValue(data);
    },
}


export default onboardingService;
