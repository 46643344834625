<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="left">
            <span class="modal-header-txt">Add widget</span>
          </div>

          <div class="right">
            <div @click="this.onHide" class="close-btn">
              <img class="cross" src="../../assets/images/cross.svg" />
            </div>
          </div>
        </div>

        <div class="info-header">
          <h3>Chart type</h3>
          <h4>Select the chart type you want to use</h4>
        </div>

        <div class="chart-list mt-3">
          <div
              class="chart-type"
              :class="selectedChartType === 'linear' && 'selected'"
              @click="toggleChart('linear')"
          >
            <img class="chart-image" src="@/assets/images/linearChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="selectedChartType === 'bar' && 'selected'"
              @click="toggleChart('bar')"
          >
            <img class="chart-image" src="@/assets/images/barChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="selectedChartType === 'pie' && 'selected'"
              @click="toggleChart('pie')"
          >
            <img class="chart-image" src="@/assets/images/pieChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="selectedChartType === 'halfPie' && 'selected'"
              @click="toggleChart('halfPie')"
          >
            <img class="chart-image" src="@/assets/images/halfPieChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="selectedChartType === 'funnel' && 'selected'"
              @click="toggleChart('funnel')"
          >
            <img class="chart-image" src="@/assets/images/funnelChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="selectedChartType === 'table' && 'selected'"
              @click="toggleChart('table')"
          >
            <img class="chart-image" src="@/assets/images/tableChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>
        </div>

        <div class="info-header">
          <h3>Time Frame</h3>
          <h4>Select the time frame for the chart</h4>
        </div>

        <div class="time-frame-list">
          <div class="time-frame" :class="selectedTimeFrame === '1' && 'selected'" @click="selectedTimeFrame = '1'">
            <span>1 day</span>
          </div>

          <div class="time-frame" :class="selectedTimeFrame === '2' && 'selected'" @click="selectedTimeFrame = '2'">
            <span>1 week</span>
          </div>

          <div class="time-frame" :class="selectedTimeFrame === '3' && 'selected'" @click="selectedTimeFrame = '3'">
            <span>1 month</span>
          </div>

          <div class="time-frame" :class="selectedTimeFrame === '4' && 'selected'" @click="selectedTimeFrame = '4'">
            <span>3 month</span>
          </div>

          <div class="time-frame" :class="selectedTimeFrame === '5' && 'selected'" @click="selectedTimeFrame = '5'">
            <span>6 month</span>
          </div>

          <div class="time-frame" :class="selectedTimeFrame === '6' && 'selected'" @click="selectedTimeFrame = '6'">
            <span>1 year</span>
          </div>
        </div>

        <div class="info-header">
          <h3>Data Filters</h3>
          <h4>Set the data filters for the chart</h4>
        </div>

        <div class="data-step-wrapper">

          <div class="data-group" v-for="filter in filterGroups">
            <div class="left">
              <img src="@/assets/images/drag.svg" />
            </div>

            <div class="right">
              <div class="filter">
                <div class="input-row">
                  <input class="data-input color" type="color" v-model="filter.color" />
                  <input class="data-input" placeholder="Group Title" v-model="filter.name" />
                </div>

                <div class="filter-row">
                  <FilterDropdown
                      :placeholder="'Select data'"
                      :actions="mainFilterOptions"
                      :on-select="select => filter.filters.selectedVariable = select.value"
                  />

                  <span class="data-text">where</span>

                  <FilterDropdown
                      :placeholder="'Select source'"
                      :actions="sourceList"
                      :for-check="filter.filters.selectedVariable"
                      :on-select="select => filter.filters.source = select"
                  />

                  <FilterDropdown
                      v-if="filter?.filters?.source?.module"
                      :placeholder="'Select source'"
                      :actions="sourceModuleList"
                      :on-select="select => filter.filters.source.module = select.value"
                  />

                  <FilterDropdown
                      v-if="filter?.filters?.source?.subModule"
                      :placeholder="'Select source'"
                      :actions="sourceSubModuleList"
                      :on-select="select => filter.filters.source.subModule = select.value"
                  />

                  <button
                      class="add-filter-button"
                      @click="() => addProgramFilter(filter)" v-if="filter?.filters?.source?.mainValue?.value === 'program' && (!filter?.filters?.source?.module || !filter?.filters?.source?.subModule)"
                  >
                    <img src="@/assets/images/add.svg" />
                  </button>

                  <span class="data-text">is</span>

                  <FilterDropdown
                      :right="true"
                      :for-check="filter?.filters?.source?.mainValue?.value"
                      :placeholder="'Select'"
                      :actions="userFilters" />
                </div>
              </div>
            </div>

            <div class="delete-wrapper" @click="removeFilterGroup(filter)">
              <img src="@/assets/images/cross.svg" />
            </div>
          </div>


          <div class="add-filter-group">
            <button class="add-filter-group-button" @click="addFilterGroup">
              <img src="@/assets/images/plusBlue.svg" />
              <span>Add group</span>
            </button>
          </div>
        </div>

        <div class="footer-controls">
          <button class="btn-style" @click="onHide">Save</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import StepProgress from "@/components/StepProgress.vue";
import FilterDropdown from "@/components/dashboard-components/FilterDropdown.vue";

export default {
  components: {FilterDropdown, StepProgress},
  data() {
    return {
      selectedChartType: null,
      currentStep: 1,
      selectedTimeFrame: null,
      mainFilterOptions: [
        {
          name: 'Users',
          value: 'userCount',
        },
        {
          name: 'Time Spent',
          value: 'timeSpent',
        },
        {
          name: 'Files',
          value: 'files',
        },
      ],
      sourceList: [
        {
          for: ['userCount', 'timeSpent', 'files'],
          name: 'Program',
          value: 'program',
          subList: [
            {
              name: 'Program 1',
              value: 'program_1'
            },
            {
              name: 'Program 2',
              value: 'program_2'
            },
            {
              name: 'Program 3',
              value: 'program_3'
            }
          ]
        },
        {
          for: ['userCount', 'timeSpent', 'files'],
          name: 'Test',
          value: 'test',
          subList: [
            {
              name: 'Test 1',
              value: 'test_1'
            },
            {
              name: 'Test 2',
              value: 'test_2'
            },
            {
              name: 'Test 3',
              value: 'test_3'
            }
          ]
        },
        {
          for: ['userCount', 'timeSpent'],
          name: 'Onboarding',
          value: 'onboarding',
          subList: [
            {
              name: 'Gender',
              value: 'gender'
            },
            {
              name: 'Martial Status',
              value: 'martial_status'
            },
            {
              name: 'Education Level',
              value: 'education_level'
            }
          ]
        }
      ],
      userFilters: [
        {
          for: ['program', 'test'],
          name: 'Completed',
          value: 'completed'
        },
        {
          for: ['program', 'test'],
          name: 'Pending',
          value: 'pending'
        },
        {
          for: ['program', 'test'],
          name: 'Assigned',
          value: 'assigned'
        },
        {
          for: ['program', 'test'],
          name: 'Not Assigned',
          value: 'not_assigned'
        },
        {
          for: ['program', 'test'],
          name: 'Draft',
          value: 'draft'
        },
        {
          for: ['onboarding'],
          name: 'Gender',
          value: 'gender',
          subList: [
            {
              name: 'Male',
              value: 'male'
            },
            {
              name: 'Female',
              value: 'female'
            },
            {
              name: 'Other',
              value: 'other'
            }
          ]
        },
        {
          for: ['onboarding'],
          name: 'Martial Status',
          value: 'martial_status',
          subList: [
            {
              name: 'Single',
              value: 'single'
            },
            {
              name: 'Married',
              value: 'married'
            },
            {
              name: 'Divorced',
              value: 'divorced'
            }
          ]
        },
        {
          for: ['onboarding'],
          name: 'Education Level',
          value: 'education_level',
          subList: [
            {
              name: 'High School',
              value: 'high_school'
            },
            {
              name: 'Bachelor',
              value: 'bachelor'
            },
            {
              name: 'Master',
              value: 'master'
            }
          ]
        },

      ],
      sourceModuleList: [
        {
          name: 'Module 1',
          value: 'module_1'
        },
        {
          name: 'Module 2',
          value: 'module_2'
        },
        {
          name: 'Module 3',
          value: 'module_3'
        }
      ],
      sourceSubModuleList: [
        {
          name: 'Sub Module 1',
          value: 'sub_module_1'
        },
        {
          name: 'Sub Module 2',
          value: 'sub_module_2'
        },
        {
          name: 'Sub Module 3',
          value: 'sub_module_3'
        }
      ],
      filterGroups: [{
        name: '',
        color: '',
        filters: {},
      }]

    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    toggleChart(type) {
      this.selectedChartType = type;
      this.currentStep = 2;
    },
    addProgramFilter(filter) {
      if (!filter.filters.source.module) {
        filter.filters.source.module = 'null';
      } else if (!filter.filters.source.subModule) {
        filter.filters.source.subModule = 'null';
      }
    },
    removeFilterGroup(filter) {
      if (this.filterGroups.length === 1) {
        this.$snackbar.add({
          text: 'You cannot delete the last filter group',
          type: 'warning'
        });
        return;
      }
      this.filterGroups = this.filterGroups.filter(f => f !== filter);
    },
    addFilterGroup() {
      this.filterGroups.push({
        name: '',
        color: '',
        filters: {},
      });
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  padding: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 12px;
  max-height: calc(100vh - 48px);

}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 50%;
}

.cross{
  padding: 8px;
}

.modal-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border: none;
}

.modal-header .left{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.modal-header .right{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modal-header-description{
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}

.step-header-wrapper {
  margin-top: 24px;
}

.step-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.chart-type {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  border-radius: 9px;
  transition: all 0.2s ease;
}


.chart-type .chart-image {
  width: 160px;
}

.chart-type .check {
  opacity: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease;
}

.chart-type.selected, .chart-type:hover {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.chart-type.selected .check {
  opacity: 1;
}

.slide-horizontal-enter-active,
.slide-horizontal-leave-active {
  transition: all 0.3s ease;
}

.slide-horizontal-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-horizontal-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.step-wrapper {
  position: relative;
  width: 100%;
}

.data-group {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 9px;
}

.data-group .left {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.data-group .filter-row {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  gap: 4px;
  padding: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.data-input {
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  padding: 8px;
  font-size: 10px;
}

.data-input.color {
  background-color: #fff;
  height: 36px;
  width: 36px;
  aspect-ratio: 1;
}

.data-group .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-step-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.add-filter-button {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: all 0.2s ease;
}

.add-filter-button img {
  width: 16px;
  height: 16px;
}

.add-filter-button:hover {
  background-color: #f1f1f1;
}

.data-text {
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-size: 10px;
  font-style: normal;
  line-height: 10px;
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 3px;
  flex: 1;
}

.add-filter-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.add-filter-group-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  transition: all 0.2s ease;
  gap: 3px;
  padding: 5px 10px;
}

.add-filter-group-button span {
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
}

.add-filter-group-button img {
  width: 18px;
  height: 18px;
}

.add-filter-group-button:hover {
  background-color: #f1f1f1;
}

.data-group:hover .delete-wrapper {
  opacity: 1;
}

.delete-wrapper {
  opacity: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  display: flex;
  top: -12px;
  right: -10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.delete-wrapper img {
  width: 8px;
  height: 8px;
}

.delete-wrapper:hover {
  background-color: #f1f1f1;
}

.info-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 24px;
}

.info-header h3 {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 14px;
  font-style: normal;
  line-height: 14px;
}

.info-header h4 {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
}

.time-frame-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 24px;
}

.time-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  border-radius: 9px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.time-frame span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  transition: all 0.2s ease;
}

.time-frame:hover {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.time-frame.selected {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.time-frame.selected span {
  color: #4A68F8;
}

.footer-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 120px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  outline: none;
  border: none;
}

@media (max-width: 1170px) {
  .chart-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
