<template>
  <layout padding="0">
    <div class="main-container">
      <div class="column-container">
        <div class="input-holder">
          <span class="input-header">{{ $t('advertisement_title') }}</span>
          <input :placeholder="this.$t('enter_new_position_name')" class="form-input" type="text" v-model="title">
        </div>
        <div class="input-holder">
          <span class="input-header">İşe Alım Programı</span>
          <endless-scroll class="select-class"></endless-scroll>
        </div>
        <div class="input-holder">
          <span class="input-header">Teklif Bildirimi</span>
          <endless-scroll class="select-class"></endless-scroll>
        </div>
        <div class="input-holder">
          <span class="input-header">{{ $t('description') }}</span>
          <textarea :placeholder="this.$t('enter_position_description')" style="min-height: 100px" class="form-input" type="text" v-model="description"></textarea>
        </div>
        <div class="input-holder">
          <span class="input-header">Teklif Gönderilen Kişi Listesi</span>
          <endless-scroll class="select-class"></endless-scroll>
        </div>
        <div class="input-holder">
          <span class="input-header">İşe Alınan Kişi Listesi</span>
          <endless-scroll class="select-class"></endless-scroll>
        </div>
        <div class="action-row">
          <span class="template-text">{{ $t('save_as_template') }}</span>
          <button style="width: 100px" class="dark-button">
            <span>Kaydet</span>
          </button>
        </div>
      </div>

      <div class="column-container">
        <div class="input-holder">
          <span class="preview-header">{{$t('advertisement_preview')}}</span>
          <PositionReviewCard
          :title="this.title"
          :description="this.description"
          :value1="this.value1"
          :value2="this.value2"
          :is-loading="false"
          />
        </div>
        <span class="settings-text">{{ $t('settings_title') }}</span>
        <div class="input-holder">
          <span class="settings-header">{{$t('published')}}</span>
          <div class="settings-holder">
            <span class="settings-label">İlanın aktif/pasiflik durumunu ayarlayın:</span>
          <Switch></Switch>
         </div>
        </div>
        <div class="settings-holder">
        <div class="settings-input-holder">
          <span class="settings-header">Hedeflenen İşe Alım Sayısı</span>
          <span class="settings-label">İşe alım sayısını ayarlayın:</span>
        </div>
          <input :placeholder="this.$t('enter_new_position_name')" style="width: 170px" class="form-input" type="number" v-model="value1">
        </div>

        <div class="input-holder">
          <span class="settings-header">Başlangıç</span>
          <div class="settings-holder">
            <span class="settings-label">Program bu tarihten itibaren katılımcılara açık olacaktır.</span>
            <div class="text-input">
              <input class="form-input" placeholder="Start Date (DD-MM-YYYY)" type="date"/>
            </div>
          </div>
        </div>

        <div class="input-holder">
          <span class="settings-header">Bitiş</span>
          <div class="settings-holder">
            <span class="settings-label">Program bu tarihe kadar katılımcılara açık olacaktır.</span>
            <div class="text-input">
              <input class="form-input" placeholder="Start Date (DD-MM-YYYY)" type="date"/>
            </div>
          </div>
        </div>

      </div>
    </div>

  </layout>

</template>
<script>
import Layout from "@/components/layouts/Layout.vue";
import Switch from "@/components/Switch.vue";
import EndlessScroll from "@/components/EndlessScroll.vue";
import PositionReviewCard from "@/components/PositionReviewCard.vue";

export default {
  components: {
    PositionReviewCard,
    EndlessScroll,
    Switch,
    Layout,
  },
  data(){
    return{
      title:null,
      description:null,
      value1:null,
      value2:null,
    }
  }
}
</script>

<style scoped>
.select-class{
  width: 400px;
}
.settings-text {
  font-family: 'euclid_medium', sans-serif;
  font-size:16px;
  color:#5289EC;
  margin-top:20px;
  margin-bottom:15px;
}
.settings-input-holder{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: max-content;
  height: min-content;
}
.settings-label{
  font-family:'euclid_medium', sans-serif;
  font-size: 14px;
  color:#73839B;
}
.settings-header{
  font-family:'euclid_semi_bold', sans-serif;
  font-size: 16px;
  color: #000000;
}
.settings-holder{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}
.preview-header{
  font-family: 'euclid_medium', sans-serif;
  font-size:16px;
  color:#5289EC;
}
.template-text{
  font-family: 'euclid_regular', sans-serif;
  font-size: 16px;
  text-decoration: underline;
  color:#73839B;
  cursor: pointer;
}
.action-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  justify-content: flex-end;
}
.input-header{
  font-family:'euclid_medium', sans-serif;
  font-size: 16px;
  color: #565860;
}
.input-holder{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 10px;
  width: 400px;
  height: min-content;
}
.main-container {
  padding:40px 80px 40px 80px;
  flex-direction: row;
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: center;
}
.column-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>