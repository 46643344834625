<template>
  <Layout>
    <div class="action-bar">
      <button class="outline-action-button" :class="isEditing && 'active'" @click="toggleEdit">
        {{ isEditing ? $t('done') : $t('edit') }}
      </button>

      <button class="action-button" @click="toggleAddWidgetModal">
        {{$t('add_widget')}}
      </button>
    </div>

    <div class="grid-stack">
      <WidgetWrapper
          v-for="widget in widgets"
          :is-loading="isLoading"
          :key="widget.id"
          :data="widget"
          :widgets-list="chartData"
          :is-editing="isEditing"
          @delete="deleteWidget"
      />
    </div>

    <WidgetModal
        :visible="addWidgetModal"
        :on-hide="toggleAddWidgetModal"
    />

  </Layout>
</template>

<script setup>
import { GridStack } from "gridstack";
import Layout from "@/components/layouts/Layout.vue";
import {nextTick, onMounted, ref} from "vue";
import WidgetWrapper from "@/components/dashboard-components/WidgetWrapper.vue";
import dashboardService from "@/service/dashboardService";
import {handleErrorResponse} from "@/utils/utils";
import WidgetModal from "@/components/modals/WidgetModal.vue";

const isLoading = ref(true);
const chartData = ref([]);
const grid = ref(null);
const addWidgetModal = ref(false);
const widgets = ref([
  {
    id: 'loading-1',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-2',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 3,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-3',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 6,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-4',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 9,
      y: 0,
      w: 3,
      h: 2,
    },
  },
  {
    id: 'loading-5',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 2,
      w: 6,
      h: 4,
    },
  },
  {
    id: 'loading-6',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 6,
      y: 2,
      w: 6,
      h: 4,
    },
  },
  {
    id: 'loading-7',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 0,
      y: 6,
      w: 8,
      h: 4,
    },
  },
  {
    id: 'loading-8',
    title: 'Loading',
    type: 'number',
    grid: {
      x: 8,
      y: 6,
      w: 4,
      h: 4,
    },
  },
]);

const widgetTypes = [];

function getDashboardData() {
  dashboardService.getDashboard().then((response) => {
    widgets.value = response.data.data.map(item => {
      return {
        id: item.reportId,
        title: item.title,
        type: item.type,
        grid: item.grid,
      };
    });
    getChartData();
  }).catch((error) => {
    handleErrorResponse(error, $snackbar);
  });
}

function getDashboardBackData() {
  dashboardService.getDashboardBackStore().then((response) => {

    widgetTypes.value = response.data.data.map(item => {
      return {
        id: item.reportId,
        title: item.title,
        type: item.type,
        grid: {
          w: 3,
          h: 2,
        }
      };
    });

  }).catch((error) => {
    handleErrorResponse(error, $snackbar);
  });
}

function getChartData() {
  dashboardService.getChartData().then((response) => {
    chartData.value = response.data.data;
    isLoading.value = false;

    initGridStack();
  }).catch((error) => {
    console.log(error);
    handleErrorResponse(error, $snackbar);
  });
}

function initGridStack() {
  if (grid.value) {
    grid.value.removeAll();
  }

  grid.value = GridStack.init({
    column: 4,
    cellHeight: 100,
    margin: 10,
    disableResize: !isEditing.value,
    disableDrag: !isEditing.value,
    columnOpts: {
      breakpointForWindow: true,
      breakpoints: [{w:700, c:1},{w:850, c:3},{w:950, c:6},{w:1100, c:8}]
    },
    float: false,
  });
  makeWidgets(widgets.value);
}

function makeWidgets(widgets) {
  widgets.forEach((widget) => {
    console.warn(widget);
    makeWidget(widget);
  });
}
function makeWidget(item) {
  const elSelector = `#${item.id}`;
  return grid.value.makeWidget(elSelector);
}

function toggleAddWidgetModal() {
  addWidgetModal.value = !addWidgetModal.value;
}

async function addWidget(widget) {
  if (widgets.value.find((w) => w.id === widget.id)) {
    $snackbar.add({
      text: "Widget already added",
      type: "warning",
    });
    return;
  }

  widgets.value.push(widget);
  await nextTick();
  makeWidget(widget);
}

function deleteWidget(widget) {
  const index = widgets.value.findIndex((w) => w.id === widget.id);
  if (index === -1) {
    return;
  }
  const selector = `#${CSS.escape(widget.id)}`;
  grid.value.removeWidget(selector);
  grid.value.compact();
  widgets.value.splice(index, 1);
}

const isEditing = ref(false);

function toggleEdit() {
  if (isEditing.value) {
    grid.value.disable();

    const gridItems = grid.value.getGridItems();
    gridItems.map((item) => {
      const widget = widgets.value.find((w) => w.id === item.gridstackNode?.id);

      if (widget) {
        widget.grid = {
          x: item.gridstackNode.x,
          y: item.gridstackNode.y,
          w: item.gridstackNode.w,
          h: item.gridstackNode.h,
        };
      }
    });

    dashboardService.saveDashboard(widgets.value).then(() => {
      $snackbar.add({
        text: "Dashboard saved",
        type: "success",
      });
    }).catch((error) => {
      handleErrorResponse(error, $snackbar);
    });
  } else {
    grid.value.enable();
  }
  isEditing.value = !isEditing.value;
}

onMounted(() => {
  getDashboardData();
  getDashboardBackData();
  initGridStack();
});
</script>

<style scoped>
.action-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 10px;
}

.add-widget-dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 10px;
  z-index: 999;
}

.dropdown-item {
  cursor: pointer;
  padding: 10px 10px;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-radius: 9px;
}

.dropdown-item:hover {
  background-color: #f5f7f9;
}
</style>
