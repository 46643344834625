<template>
  <div class="action-wrapper" :class="type === 'dark' ? 'dark' : 'light'">
    <div class="go-dropdown-button" @click="toggleDropdown" v-click-outside="actionDropdownOutsideClick">
      {{ selected ? selected.name : placeholder }}
      <img src="@/assets/images/arrowdown.svg" class="dropdown-arrow" :class="dropdownActive && 'arrow-up'"/>
    </div>

    <div class="action-dropdown" :class="right ? 'right' : 'left'" v-if="dropdownActive">
      <div
          class="action-list"
          v-for="action in actionList"
          @click="handleMainSelect(action)"
          @mouseover="showSubList(action)"
          @mouseleave="hideSubList"
          :key="action?.value"
      >
        {{ action?.name }}
        <img v-if="action.subList" src="@/assets/images/arrowdown.svg" class="submenu-arrow" :class="right && 'right-arrow'"/>
      </div>
    </div>

    <div
        class="sub-dropdown"
        v-if="activeSubList"
        :style="subListPosition"
        @mouseover="keepSubList"
        @mouseleave="hideSubList"
    >
      <div
          class="action-list"
          v-for="subItem in activeSubList"
          @click="handleSubSelect(subItem)"
          :key="subItem.value"
      >
        {{ subItem.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'light'
    },
    right: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Actions'
    },
    actions: {
      type: Array,
      default: () => []
    },
    forCheck: {
      type: String,
      default: ''
    },
    onSelect: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      dropdownActive: false,
      selected: null,
      activeSubList: null,
      subListPosition: {
        top: '0px',
        left: '0px'
      },
      hideTimeout: null
    }
  },

  methods: {
    actionDropdownOutsideClick() {
      this.dropdownActive = false;
      this.activeSubList = null;
    },

    toggleDropdown() {
      if (this.actionList.length === 0) {
        return;
      }

      this.dropdownActive = !this.dropdownActive;
    },

    handleMainSelect(action) {
      if (!action.subList) {
        this.selected = action;
        this.dropdownActive = false;
        this.activeSubList = null;
        this.onSelect(action);
      }
    },

    handleSubSelect(subItem) {
      this.selected = subItem;
      this.dropdownActive = false;
      this.activeSubList = null;

      const mainAction = this.actions.find(action => action.subList?.includes(subItem));
      this.onSelect({
        ...subItem,
        mainValue: mainAction
      });
    },

    showSubList(action) {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }

      if (action.subList) {
        this.activeSubList = action.subList;
        const elem = event.target;
        const rect = elem.getBoundingClientRect();
        const parentRect = elem.closest('.action-dropdown').getBoundingClientRect();


       if (this.right) {
          this.subListPosition = {
            top: `${rect.top - parentRect.top}px`,
            right: `${parentRect.width + 10}px`
          };
        } else {
          this.subListPosition = {
            top: `${rect.top - parentRect.top}px`,
            left: `${parentRect.width + 10}px`
          };
        }
      }
    },

    hideSubList() {
      this.hideTimeout = setTimeout(() => {
        this.activeSubList = null;
      }, 100);
    },

    keepSubList() {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }
    }
  },
  computed: {
    actionList() {
      return this.actions.filter(action => {
        return !action.for || action.for.includes(this.forCheck);
      });
    }
  }
}
</script>

<style scoped>
.action-wrapper {
  position: relative;
  overflow: visible;
}

.go-dropdown-button {
  cursor: pointer;
  font-size: 10px;
  padding: 7px 15px;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dark .go-dropdown-button {
  background-color: #f1f1f1;
  border: 1px solid #ececec;
}

.action-dropdown {
  position: absolute;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  top: 40px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  z-index: 99999;
}

.sub-dropdown {
  position: absolute;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  z-index: 99999;
}

.action-list {
  cursor: pointer;
  font-size: 10px;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  transition: background 0.2s;
}

.action-list:hover {
  background: #f1f1f1;
}

.dropdown-arrow {
  transition: transform 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
}

.submenu-arrow {
  transform: rotate(-90deg);
  width: 12px;
  height: 12px;
}

.right-arrow {
  transform: rotate(90deg);
}
</style>
