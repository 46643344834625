<template>
  <div class="node-dropdown-wrapper" :class="theme" v-click-outside="handleClickOutside">
    <div class="node-dropdown" @click="toggleNodeDropdown" v-if="!loading">
      <span :key="selectedValue" class="dropdown-name-placeholder" v-if="selectedValue === undefined || selectedValue === '-1'">
        {{$t('select')}}
      </span>

      <span :key="selectedValue" class="dropdown-name" v-else>
        {{showValueAsSelected ? selectedValue : getSelectedText(selectedValue)}}
      </span>

      <img src="@/assets/images/chevronDown.svg" class="dropdown-arrow" :class="isOpen && 'rotate'" />
    </div>

    <div class="node-dropdown-body" v-if="isOpen">
      <input type="text" class="search-input" :placeholder="$t('search')" v-model="searchValue" v-if="searchEnabled" />

      <div class="option-list" :class="!searchEnabled && 'without-search'">
        <div v-if="tempList.length > 0" v-for="(option, index) in tempList" :key="index" class="node-dropdown-option" @click="handleSelect(option.value)">
          <img v-if="option.icon" :src="option.icon" class="dropdown-arrow" />
          <span>{{ option.text }}</span>
        </div>

        <div v-else class="node-dropdown-option">
          <span>{{$t('no_options_available')}}</span>
        </div>
      </div>
    </div>

    <div v-else-if="loading" class="node-dropdown">
      <span class="dropdown-name-placeholder">{{$t('blog_loading')}}</span>
    </div>
  </div>
</template>

<script>
import {truncate} from "@/utils/utils";

export default {
  name: 'NodeDropdown',
  data() {
    return {
      isOpen: false,
      searchValue: '',
      tempList: []
    }
  },
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    searchEnabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: '-1'
    },
    onSelect: {
      type: Function,
      required: true
    },
    showValueAsSelected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClickOutside() {
      this.isOpen = false;
    },
    toggleNodeDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleSelect(option) {
      this.onSelect(option);
      this.isOpen = false;
    },
    getSelectedText(value) {
      const selectedOption = this.options.find(option => option.value === value);

      return selectedOption ? truncate(selectedOption.text, 50) : '';
    }
  },
  watch: {
    searchValue(value) {
      if (value) {
        this.tempList = this.options.filter(option => option.text.toLowerCase().includes(value.toLowerCase()));
      } else {
        this.tempList = this.options;
      }
    },
    options: {
      handler(value) {
        this.tempList = value;
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.node-dropdown-wrapper {
  position: relative;
  width: 100%;
}

.node-dropdown {
  background: #fff;
  border: 1px solid #4A68F8;
  padding: 10px 14px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: background 0.2s;
  gap: 10px;
  height: 100%;
}

.node-dropdown-wrapper.dark .node-dropdown {
  border: 1px solid #101828;
}

.node-dropdown:hover {
  background: #D8DDEC;
}

.node-dropdown-body {
  position: absolute;
  min-width: 100%;
  width: auto;
  white-space: nowrap;
  top: calc(100% + 5px);
  left: 0;
  background: #fff;
  border: 1px solid #4A68F8;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  max-height: 300px;
  max-width: 500px;
}

.node-dropdown-wrapper.dark .node-dropdown-body {
  border: 1px solid #101828;
}

.node-dropdown-option {
  padding: 7px 14px;
  cursor: pointer;
  transition: background 0.2s;
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  text-wrap: wrap;
}

.search-input {
  padding: 7px 14px;
  border: 1px solid #4A68F8;
  border-radius: 6px;
  margin: 10px;
  outline: none;
}

.node-dropdown-wrapper.dark .search-input {
  border: 1px solid #101828;
}

.node-dropdown-option img {
  width: 12px;
  height: 12px;
}

.node-dropdown-option span {
  font-size: 12px;
  color: #101828;
}

.node-dropdown-option:hover {
  background: #D8DDEC;
}

.node-dropdown-option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}


.without-search .node-dropdown-option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}


.dropdown-name {
  font-size: 16px;
  color: #101828;
  white-space: nowrap;
}

.dropdown-name-placeholder {
  font-size: 16px;
  color: #B0B0B0;
  white-space: nowrap;
}

.dropdown-arrow {
  width: 18px;
  height: 18px;
  transition: transform 0.3s;
}

.dropdown-arrow.rotate {
  transform: rotate(180deg);
}

.option-list {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
