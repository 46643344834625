import Login from "@/pages/Login.vue";
import sidebarRoutes from "@/router/sidebarRoutes";
import CompanyDetails from "@/pages/CompanyDetails.vue";
import UserDetails from "@/pages/UserDetails.vue";
import ExpertDetails from "@/pages/ExpertDetails.vue";
import Settings from "@/pages/Settings.vue";
import TestDetails from "@/pages/TestDetails.vue";
import TherapyDetails from "@/pages/TherapyDetails.vue";
import GameLabDetails from "@/pages/GameDetails.vue";
import BlogDetails from "@/pages/BlogDetails.vue";
import PageDetails from "@/pages/PageDetails.vue";
import JourneyDetails from "@/pages/JourneyDetails.vue";
import NotificationDetails from "@/pages/NotificationDetails.vue";
import ReportDetails from "@/pages/ReportDetails.vue";
import SliderDetails from "@/pages/SliderDetails.vue";
import ImageEditor from "@/pages/ImageEditor.vue";
import OnboardingDetails from "@/pages/OnboardingDetails.vue";
import PositionDetails from "@/pages/PositionDetails.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/companies/detail/:id',
        name: 'CompanyDetail',
        component: CompanyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/detail/:id',
        name: 'UserDetail',
        component: UserDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/test/:id',
        name: 'Test',
        component: TestDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/experts/detail/:id',
        name: 'ExpertDetail',
        component: ExpertDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/notification/:id',
        name: 'NotificationDetail',
        component: NotificationDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/blog/:id',
        name: 'BlogDetail',
        component: BlogDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/page/:id',
        name: 'PageDetail',
        component: PageDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/therapy/:id',
        name: 'TherapyDetail',
        component: TherapyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/reports/:id',
        name: 'ReportDetails',
        component: ReportDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/slider/:id',
        name: 'SliderDetails',
        component: SliderDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/onboarding/:id',
        name: 'OnboardingDetails',
        component: OnboardingDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/journeys/:id',
        name: 'JourneyDetail',
        component: JourneyDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/game/:id',
        name: 'GameDetail',
        component: GameLabDetails,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/image-editor',
        name: 'ImageEditor',
        component: ImageEditor,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/positions/:id',
        name: 'PositionDetail',
        component: PositionDetails,
        meta: {
            requiresAuth: true
        }
    },
    ...sidebarRoutes
];

export default routes;
